///include /assets/js/app/20i/allowance-service.js
///include /assets/js/plugins/network-property-set.js

"use strict"

class ServicesInfo {
    /**
     *
     * @param {string} type
     * @returns {Promise<allowanceServiceIn[]>}
     */
    static async fetchAllowances(type) {
        const urls = {
            managed_vps: `/a/service/managed_vps`,
            package_allowance: `/a/service/package_allowance`,
            wordpress_allowance: `/a/service/wordpress_allowance`,
        }
        return await $.ajax({
            method: "get",
            url: urls[type],
            data: {
                fields: [
                    "id",
                    "name",
                    "count",
                    "expiryDate",
                    "packages.hasWebsiteTurbo",
                    "packages.websiteTurbo",
                    "productSpec",
                    "serviceType",
                    "packages.id",
                    "packages.name",
                    "packages.names",
                    "packages.packageTypeName",
                    "packages.created"
                ]
            },
        })
    }

    constructor() {
        this._forLocation = "*"
        this._forPackageLabel = ""
        this._forUser = null
        this._forPackageTypeName = null
        this._forSsl = null
        this._forEnabled = null
        this._forTurbo = null
        this._forPlatform = null
        this._forDomain = null
        this._page = 0
        this._searchTerm = ""
        this._sortAscending = true
        this._sortField = "name"

        this._startDate = null
        this._endDate = null

        this.byType = {
            /** @type {?ManagedVpsService[]} */
            managed_vps: null,
            /** @type {?PackageService[]} */
            package: null,
            /** @type {?PackageAllowanceService[]} */
            package_allowance: null,
            /** @type {?WordpressAllowanceService[]} */
            wordpress_allowance: null,
        }
        /** @type {?PackageService[]} */
        this.allowancePackages = []
        this.nonAllowancePackages = null
        this.packageCount = null
        this.pageLength = 100
        this.servicePackageCount = null
        /** @type {?Store} */
        this.store               = null;
        this.fetch()

        this.fetchAllAllowances()
    }

    /**
     * @type {?string}
     */
    get forLocation() {
        return this._forLocation
    }
    set forLocation(dcLocation) {
        this._forLocation = dcLocation
        this.fetch()
    }

    /**
     * @type {?string}
     */
    get forUser() {
        return this._forUser
    }
    set forUser(user) {
        this._forUser = user
        this.fetch()
    }

    get forPackageLabel() {
        return this._forPackageLabel
    }
    set forPackageLabel(label) {
        this._forPackageLabel = label
        this.fetch()
    }

    get forPackageTypeName() {
        return this._forPackageTypeName
    }
    set forPackageTypeName(packgaeTypeName) {
        this._forPackageTypeName = packgaeTypeName
        this.fetch()
    }

    get forSsl() {
        return this._forSsl
    }
    set forSsl(state) {
        this._forSsl = state
        this.fetch()
    }

    get forEnabled() {
        return this._forEnabled
    }
    set forEnabled(state) {
        this._forEnabled = state
        this.fetch()
    }

    get forTurbo() {
        return this._forTurbo
    }
    set forTurbo(state) {
        this._forTurbo = state
        this.fetch()
    }

    get forPlatform() {
        return this._forPlatform
    }
    set forPlatform(platform) {
        this._forPlatform = platform
        this.fetch()
    }

    get forDomain() {
        return this._forDomain
    }
    set forDomain(state) {
        this._forDomain = state
        this.fetch()
    }

    get startDate() {
        return this._startDate
    }
    set startDate(d) {
        this._startDate = d
        this.fetch()
    }

    get endDate() {
        return this._endDate
    }
    set endDate(d) {
        this._endDate = d
        this.fetch()
    }

    get hasNonAllowancePackages() {
        if(this.packageCount === null) return null
        return !!this.packageCount
    }

    get hasPackages() {
        return(
            this.hasNonAllowancePackages ||
            (
                this.byType.package_allowance &&
                !!this.byType.package_allowance.length
            ) ||
            (
                this.byType.wordpress_allowance &&
                !!this.byType.wordpress_allowance.length
            ) ||
            (
                this.byType.managed_vps &&
                !!this.byType.managed_vps.length
            )
        )
    }

    get packageRequestBody() {
        /** @type {*[]} */
        const selectors = []
        selectors.push({
            $or: [
                {
                    packageBundleTypeId: {
                        $regex: "."
                    },
                },
                {
                    productSpec: "web-free",
                },
            ],
        })

        if (this.forLocation != "*") {
            selectors.push({
                "location": {
                    $regex: `${this.forLocation}`
                }
            })
        }

        if(this.searchTerm != "") {
            const regex = this.searchTerm.replace(/[.]/g, "[.]")
            selectors.push({
                $or: [
                    {
                        name: {
                            $regex: regex,
                        },
                    },
                    {
                        names: {
                            $elemMatch: {
                                $regex: regex,
                            },
                        },
                    }
                ],
            })
        }

        if(this.forSsl !== null) {
            selectors.push({
                hasSslCache: {
                    $regex: this.forSsl ? "1" : "0"
                }
            })
        }

        if(this.forEnabled !== null) {
            selectors.push({
                enabledCache: {
                    $regex: this.forEnabled ? "1" : "0"
                }
            })
        }
        if(this.forTurbo !== null) {
            selectors.push({
                hasWebsiteTurbo: {
                    $regex: this.forTurbo ? "1" : "0"
                }
            })
        }

        if(this.forDomain !== null) {
            selectors.push({
                hasDomainCache: {
                    $regex: this.forDomain ? "1" : "0"
                }
            })
        }

        if(this.forPlatform !== null) {
            selectors.push({
                web: {
                    $elemMatch: {
                        platform: {
                            $regex: this.forPlatform
                        }
                    }
                }
            })
        }

        if(this.startDate) {
            selectors.push({
                createdAt: {
                    $gt: this.startDate
                }
            })
        }

        if(this.endDate) {
            selectors.push({
                createdAt: {
                    $lt: this.endDate
                }
            })
        }

        if(this.forPackageLabel) {
            selectors.push({
                packageLabel: {
                    $elemMatch: {
                        labelId: this.forPackageLabel
                    }
                }
            })
        }

        if(this.forPackageTypeName) {
            selectors.push({
                packageTypeName: {
                    $regex: `^${this.escapeRegexString(this.forPackageTypeName)}`
                }
            })
        }

        if(this.forUser !== null) {
            if(this.forUser && this.forUser !== "*") {
                selectors.push({
                    accessControl: {
                        $elemMatch: {
                            userTuple: this.forUser
                        },
                    }
                })
            } else if(this.forUser === "*") {
                selectors.push({
                    accessControl: {
                        $elemMatch: {
                            userTuple: {
                                $regex: "^stack-user:",
                            }
                        }
                    }
                })
            } else {
                selectors.push({
                    accessControl: {
                        $not: {
                            $elemMatch: {
                                userTuple: {
                                    $regex: "^stack-user:",
                                },
                            },
                        },
                    }
                })
            }
        }

        return {
            selector: selectors.length == 1 ?
                selectors[0] :
                {$and: selectors},
        }
    }

    get page() {
        return this._page
    }
    set page(v) {
        this._page = v
        this.fetch(false)
    }

    /**
     * @type {string}
     */
    get searchTerm() {
        return this._searchTerm
    }
    set searchTerm(v) {
        this._searchTerm = v
        this.fetch()
    }

    get serviceHasNonAllowancePackages() {
        if(this.servicePackageCount === null) return null
        return !!this.servicePackageCount
    }

    /**
     * @type {?boolean} True if the reseller or similar has any packages (rather
     * than the selected user)
     */
    get serviceHasPackages() {
        return(
            this.serviceHasNonAllowancePackages ||
            (
                this.byType.package_allowance &&
                !!this.byType.package_allowance.length
            ) ||
            (
                this.byType.wordpress_allowance &&
                !!this.byType.wordpress_allowance.length
            ) ||
            (
                this.byType.managed_vps &&
                !!this.byType.managed_vps.length
            )
        )
    }

    /** @type {boolean} */
    get sortAscending() {
        return this._sortAscending
    }
    set sortAscending(v) {
        if(v != this._sortAscending) {
            this._sortAscending = v
            this.fetch(false)
        }
    }

    /**
     * @type {string}
     */
    get sortField() {
        return this._sortField
    }
    set sortField(v) {
        if(v != this._sortField) {
            this._sortField = v
            this._sortAscending = true
            this.fetch(false)
        }
    }

    /**
     *
     * @param {boolean} include_meta
     */
    async fetch(include_meta = true, non_skip = false) {
        if(this.fetchP) {
            this.fetchP.abort()
        }
        if(include_meta) {
            this.packageCount = await NetworkPropertySet.prototype.collectionLength(
                `/a/package`,
                this.packageRequestBody
            )
            if(!(this.forUser || this.searchTerm)) {
                this.servicePackageCount = this.packageCount
            }
        }
        this.nonAllowancePackages = null
        if(non_skip) {
            this.page = 0
        }

        const result = await this.fetchPackages(non_skip ? null : this.page * this.pageLength, this.pageLength)

        // This is done here because managed VPS packages are wanted to show in the reseller
        // package list.  The reseller list in the 'nonAllowance' so we are simply replacing
        // the non allowance package service with the one from the managed vps
        var managedVpsAllowanceById = {};
        if( this.byType.managed_vps && this.byType.managed_vps.length > 0 ){
            for(const as of this.byType.managed_vps){
                for(const pkg of as.packages){
                    managedVpsAllowanceById[pkg.id] = pkg;
                }
            }
        }

        this.nonAllowancePackages = result.map( (p) => {
            if( managedVpsAllowanceById[p.id] ){
                p.pseudoAllowance = managedVpsAllowanceById[p.id].allowance;
            }
            return new PackageService(p)
        })
    }

    fetchAllAllowances() {
        return Promise.all([
            ServicesInfo.fetchAllowances("package_allowance").then(
                result => this.byType.package_allowance = result.map((r) => {
                    const allowance = new PackageAllowanceService(r)
                    if (
                        allowance.packages &&
                        Array.isArray(allowance.packages) &&
                        allowance.packages.length
                    ) {
                        allowance.packages.forEach(p => this.allowancePackages.push(p))
                    }
                    if (this.store) {
                        allowance.setStore(this.store)
                    }
                    return allowance
                })
            ),
            ServicesInfo.fetchAllowances("wordpress_allowance").then(
                result => this.byType.wordpress_allowance = result.map((r) => {
                    const allowance = new WordpressAllowanceService(r)
                    if (
                        allowance.packages &&
                        Array.isArray(allowance.packages) &&
                        allowance.packages.length
                    ) {
                        allowance.packages.forEach(p => this.allowancePackages.push(p))
                    }
                    if (this.store) {
                        allowance.setStore(this.store)
                    }
                    return allowance
                })
            ),
            ServicesInfo.fetchAllowances("managed_vps").then(
                result => this.byType.managed_vps = result.map((r) => {
                    const allowance = new ManagedVpsAllowanceService(r)
                    if (
                        allowance.packages &&
                        Array.isArray(allowance.packages) &&
                        allowance.packages.length
                    ) {
                        allowance.packages.forEach(p => this.allowancePackages.push(p))
                    }
                    if (this.store) {
                        allowance.setStore(this.store)
                    }
                    return allowance
                })
            )
        ])
    }

    /**
     *
     * @param {?number} skip
     * @param {?number} limit
     * @returns {JQuery.jqXHR<packageIn[]>}
     */
    fetchPackages(skip, limit) {
        return $.ajax({
            method: "get",
            url: `/a/package`,
            data: Object.assign(
                {
                    fields: [
                        "id",
                        "hasWebsiteTurbo",
                        "location",
                        "names",
                        "name",
                        "packageTypeName",
                        "productSpec",
                        "created",
                        "packageLabels",
                        "web.hasSsl",
                        "web.platform",
                        "websiteTurbo"
                    ],
                    limit: limit,
                    skip: skip,
                    sort: [
                        {[this.sortField]: this.sortAscending ? "asc" : "desc"}
                    ],
                },
                this.packageRequestBody
            ),
        })
    }

    /**
     * @param {string} string
     * @returns {string}
     */
    escapeRegexString(string) {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
    }
    /**
     * Set store object
     * @param {Store} store
     */
    setStore(store) {
        this.store = store;
    }
}
